'use-strict';
document.addEventListener('DOMContentLoaded', function () {
  function fixedMargin() {
    const fixedElements = document.querySelectorAll('.fixed');

    fixedElements.forEach((element) => {
      const height = element.offsetHeight;
      const nextElement = element.nextElementSibling;
      nextElement.style.marginTop = height + 'px';
    });
  }
  fixedMargin();

  function createMenu() {
    const MenuContainer = document.querySelector('#mainMenu');
    const elementsNode = document.querySelectorAll('[menu-nav]');
    let fragment = '';
    elementsNode.forEach((nodeElem, i) => {
      let text = nodeElem.getAttribute('menu-nav');
      const id = `menu-item-${i}`;
      nodeElem.setAttribute('id', id);
      const menuItem = `<li class="menu-item"><a href="#${id}" class="menu-item-link">${text}</a></li>`;
      fragment += menuItem;
    });

    MenuContainer.innerHTML = fragment;
  }
  createMenu();

  function activeMenu() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const links = document.querySelectorAll('.menu-item');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const targetElement = entry.target;
          links.forEach((link) => {
            link.querySelector('a').classList.remove('active');
          });

          const allElements = Array.from(
            document.querySelectorAll('[menu-nav]')
          );
          let index = allElements.indexOf(targetElement);
          links[index].querySelector('a').classList.add('active');
        }
      });
    }, options);

    const arr = document.querySelectorAll('[menu-nav]');
    arr.forEach((element) => {
      observer.observe(element);
    });
  }

  activeMenu();

  function removeCollapse() {
    const blockCollapse = document.querySelector('#menuContainer');
    if (window.innerWidth >= 768) {
      blockCollapse.classList.add('show');
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        blockCollapse.classList.add('show');
      }
    });
  }
  removeCollapse();
});
